<template>
    <b-overlay>
        <b-card v-if="failedLogin">
            <div class="d-flex justify-content-center"><h1 class="font-title">{{ $t('login.login_as_user_error') }}</h1> <fa class="ml-1" style="height: 2.2rem; width: 2.2rem; color: #72a5d8" icon="exclamation-triangle"/></div>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BOverlay, BCard} from 'bootstrap-vue'

    export default {
        components:{BOverlay, BCard},
        data() {
            return {
                showLoader:false,
                failedLogin:false
            }
        },
        methods:{
            async loginAsUser(user) {
                this.showLoader = true
                this.failedLogin = false
                try {
                    const response = await this.$http.get(`/api/user/v1/login_as_user/${user}`)
                    await this.$store.dispatch('user/login', response.data.token)
                    await this.$store.dispatch('user/setUserData', response.data)
                    window.location = '/e-upravnik'
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri prijavi\\n${error.message}`)
                    this.failedLogin = true
                }
            }
        },
        async mounted() {
            await this.loginAsUser(this.$route.params.user_id)
        }
    }
</script>

<style scoped>
.font-title{
    color: black;
    font-size: 2rem;
}
</style>